.about-body {
  background-color: black;
    font-family: "Oswald", sans-serif;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
  .about-area {
    text-align: center;
    min-height: 100%;
    min-width: 100%;
  }

  .about-intro {
    color: lightsteelblue;
    padding: 2rem;
    text-align: left;
    margin: 5% 15%;
  }
  .about-skills {
    color: lightsteelblue;
    padding: 2rem;
    text-align: left;
    margin-left: 20%;
  }

  .skilldesc {
    margin-left: -5%;
  }

  .modal-button {
    text-align: center;
  }
  button {
    color: lightsteelblue;
    border-color: lightsteelblue;
    width: 105px;
  }
  
  h4, h5 {
    padding: 10px;
    margin-left: 40px;
  }
  
  h1 {
    font-size: 4rem;
    margin-bottom: 50px;
  }

  h2 {
      font-size: 3rem;
  }
  
  .about-area p {
    margin-left: 50px;
  }
  
  .about-area hr {
    border-style: none;
    border-top-style: dotted;
    border-width: 5px;
    width: 15%;
    border-color: lightsteelblue;
    margin-left: 0px;
    padding-bottom: 50px;
  }
  
  .contact a {
      margin-left: 1vw;
      color: lightsteelblue;
      padding: 5px;
      background-color: mediumblue;
      border-radius: 15px;
      box-shadow: 0.7rem 0.7rem 1.4rem black;

  }

  .contact a:hover {
    color: azure;
    background-color: midnightblue;
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {

    .about-intro {
      margin-left: 5px;}

      .contact {
        padding: 10px;
        padding-bottom: 5vh;
        text-align: center;

      }
  
}