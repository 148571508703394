* {
   margin: 0;
   padding: 0; 
}
.overlay {
    background-color: rgba(0, 21, 11, 0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
}

.modalContainer {
    max-width: 800px;
    width: 100%;
    max-height: 90%;
    border-radius: 15px;
    color: lightsteelblue;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding-top: 40px;
    padding-bottom: 20px;
    animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.closeBtn {
    position: fixed;
    top: 8px;
    right: 12px;
}

.content {
    width: 100%;
    overflow-y: scroll;
    padding: 30px;
    margin-left: 25%;
}

hr {
    align-items: center;
    border-style: none;
    border-top-style: dotted;
    border-width: 5px;
    width: 30%;
    border-color: lightsteelblue;
}