@font-face {
  font-family: 'hacked';
  font-style: normal;
  font-weight: normal;
  src: local('hacked'), url('fonts/HACKED_Title.woff') format('woff');
  }

.nav-bar {
  /* background-color: black; */
  background-image: url("https://www.transparenttextures.com/patterns/rocky-wall.png");
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 0rem;
  border-bottom: 0.1rem;
  border-color: lightsteelblue;
  border-style: solid;
}

.nav-logo {
  text-align: left;
  display: inline-flex;
  align-items: center;
  background-image: url("https://www.transparenttextures.com/patterns/rocky-wall.png");
}

.nav-tray {
  text-align: right;
  background-image: url("https://www.transparenttextures.com/patterns/rocky-wall.png");
  font-family: "Oswald", sans-serif;
  padding-right: 15px;
}

.logic {
  font-family: "Special Elite", cursive;
  color: rgb(3, 145, 3);
  font-size: 0.9rem;
  margin-bottom: 0;
  padding-top: 5px;
  background-image: url("https://www.transparenttextures.com/patterns/rocky-wall.png");
}

.otaku {
  font-family: "hacked", cursive;
  background: -webkit-linear-gradient(darkorange, orangered, mediumorchid, darkorchid);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  margin-bottom: 0;
  padding-left: 5px;
}

.nav-item {
  color: lightslategray;
  padding-left: 10px;
  background-color: transparent;
}
.nav-item:hover {
  color: lightsteelblue;
  text-decoration: none;
  text-transform:uppercase
}

.nav-item-selected {
  color: lightgray;
  padding-left: 10px;
}
