footer {
  background-color: black;
  min-height: 10%;
  min-width: 100%;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0px;
}

.App-logo {
  height: 2vmax;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-footer {
  text-align: center;
  min-height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.App-link {
  color: lightsteelblue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-block {
  padding: 10px;
}
.footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-info {
  margin-bottom: 0px;
  padding-top: 10px;
  /* color: rgba(169, 169, 169, 0.219); */
  color: lightsteelblue;
}

.footer-cr {
  margin-bottom: 0px;
  padding-top: 30px;

  color: lightsteelblue;
}

.footer-link {
  color: lightsteelblue;
  /* color: rgba(169, 169, 169, 0.219); */
  padding: 1px 5px;
}
.footer-link:hover {
  color: lightslategray;
  text-decoration: none;
  text-transform: lowercase;
}

.lg {
  font-size: 14px;
}
