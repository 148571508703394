/* ContactForm.css */

.contact-form-container {
    max-width: 500px;
    padding: 20px;
    border: 1px solid lightsteelblue;
    border-radius: 8px;
  }
  
  .contact-form-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    font-weight: bold;
    width: 80px;
    margin-bottom: 5px;
  }

  .message-label {
    font-weight: bold;
    align-self: flex-start; /* Align the label to the left side */
    margin-bottom: 5px;
    margin-top: 0;
  }

  .message-textarea {
    align-self: auto; /* Align the textarea to the left side */
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid lightsteelblue;
    border-radius: 4px;
    width: 100%;
    resize: vertical
  }
  
  .contact-form input,
  .contact-form textarea {
    color: lightsteelblue;
    padding: 2px;
    margin-bottom: 15px;
    border: 1px solid lightsteelblue;
    border-radius: 4px;
    width: 100%;
  }
  
  .contact-form button {
    background-color: darkslateblue;
    color: lightsteelblue;
    border: none;
    align-self: flex-end;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 600px) {
    .contact-form-container {
      max-width: 100%;
      padding: 15px;
    }
  }
  