.port-body {
    background-color: black;
    font-family: "Oswald", sans-serif;
    color: lightsteelblue;
    min-height: 100%;
    min-width: 100%;
  }
  
  .port-area {
    text-align: center;
    min-height: 75vh;

  }

  .port-grid {
    min-height: 75vh;
    background-color: #302f2f;
    background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
    border-radius: 1%;
    margin: 2% 10%;
  }

  .port-display {
      background-color:black;
      /* background-image: url("https://www.transparenttextures.com/patterns/brick-wall-dark.png"); */
      height: 100%;
      width: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;

  }

  .port-display img {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .port-display .link {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    transform: scaleY(0);
    transition: transform 0.3s ease-in-out;
  }

  .port-display:hover .link{
    transform: scaleY(1);
  }

  .link a {
    position: absolute;
    justify-content: center;
    top: 25%;
    left: 45%;
    color: whitesmoke;
    font-size: 2rem;
  }

  .link a:hover {
    color: lightsteelblue;
  }

  .link p {
    position: absolute;
    justify-content: center;
    top: 50%;
    left: 25%;
    color: whitesmoke;
    font-size: 1.5rem;

  }

  .port-area h1 {
    padding: 4rem;
  }

  @media only screen and (max-width: 800px) {
    .port-area {
      padding-bottom: 5vh;
    }

    .port-area h1 {
      padding-bottom: 3vh;
    }
}