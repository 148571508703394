.home-body {
  background-color: black;
  font-family: "Oswald", sans-serif;
  min-height: 100%;
  min-width: 100%;
  /* background-image: url("https://www.transparenttextures.com/patterns/hexellence.png"); */
}

.home-area {
  text-align: center;
  min-height: 75%;
  min-width: 100%;
  padding: 10vh;
}

.intro {
  color: lightsteelblue;
  padding: 30px;
  text-align: left;
  padding-left: 5vw;
  word-wrap: normal;
  width: 45vw;
}

.intro h3 {
  padding: 10px;
  margin-left: 40px;
}

.intro h1 {
  font-size: 4rem;
  margin: 3rem 3rem 3rem 0rem;
}

.home-area p {
  margin-left: 50px;
}

hr {
  border-style: none;
  border-top-style: double;
  border-width: 3px;
  width: 50%;

}

.intro hr {
  border-color: lightsteelblue;
  margin-left: 50px;
}

.intro2 {
  color: lightyellow;
  padding: 30px;
  margin-left: 50%;
  padding-right: 5vw;
  text-align: left;
  align-items: right;
  word-wrap: normal;
  width: 45vw;
}


.intro2 hr {
  border-color: lightyellow;
  margin-right: 1rem;
}

.intro2 h3 {
  padding: 10px;
  margin-right: 0px;
  text-align: right;
}
.about-area h1 {
  padding-top: 4rem;
}

@media only screen and (max-width: 800px) {
  .intro {
    width: 70vw;
    padding-left: 0;
  }
  .intro2 {
    width: 70vw;
    margin:0;
    margin-left: 10vw;
  }

  .intro2 hr {
    margin-right: 0;
  }
}

@media only screen and (max-width: 400px) {
  .home-area {
    padding: 0;
  }
  .intro {
    width: 90vw;
    padding-left: 0;
    padding: 0;
  }
  .intro2 {
    width: 90vw;
    margin:0;
    margin-left: 0vw;
    padding: 0;
    padding-right: 1vw;
    padding-bottom: 10vh;
  }

  .intro2 hr {
    margin-right: 5vw;
  }
}